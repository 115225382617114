import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProjectStages(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/project_stage/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchProjectStage(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/project_stage/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addProjectStage(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/project_stage', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteProjectStage(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/project_stage', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
